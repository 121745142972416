import { Suspense, lazy } from 'react';

import BaseLayout from 'src/layouts/BaseLayout';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Final = Loader(lazy(() => import('src/content/dashboards/Final')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const SearchRag = Loader(
  lazy(() => import('src/content/applications/SearchRag'))
);
const Startup = Loader(
  lazy(() => import('src/content/applications/Startup'))
);
const Investor = Loader(
  lazy(() => import('src/content/applications/Investor'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);





const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/dashboards/final" replace />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="final" replace />
      },
      {
        path: 'final',
        element: <Final />
      },
      {
        path: 'messenger',
        element: <Messenger />
      },
      {
        path: 'searchrag',
        element: <SearchRag />
      },
      {
        path: 'startup',
        element: <Startup />
      },
      {
        path: 'investor',
        element: <Investor />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  }
];

export default routes;
